import { EmailAuthProvider } from 'firebase/auth';
import React from 'react';
import { useAuth } from 'reactfire';
import FirebaseAuth from '../../auth';

export function LoginPage() {
  const firebaseAuth = useAuth();
  return (
    <div>
      <FirebaseAuth
        uiConfig={{
          signInFlow: 'popup',
          signInSuccessUrl: '/',
          signInOptions: [EmailAuthProvider.PROVIDER_ID],
        }}
        firebaseAuth={firebaseAuth}
      />
    </div>
  );
}
