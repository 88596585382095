import {
  BUY_COURSE_CONFIRMATION_CALLBACK,
  BUY_COURSE_REQUEST,
  COLLECTION_CONFIG,
  COLLECTION_COURSE_CONFIG,
  COURSES,
  ECONOMY_PLAN,
  PREMIUM_PLAN,
  USERS,
} from '@codebee/constants';
import { LabelValue, LoadingSpinner, Section } from '@codebee/core/src';
import AppContext from '@codebee/core/src/app/AppContext';
import { useUserProfile } from '@codebee/core/src/hooks';
import { BuyCourseRequest, Course, RazorpayConfirmationCallBack, Transaction } from '@codebee/models';
import { collection, doc } from '@firebase/firestore';
import { httpsCallable } from '@firebase/functions';
import { Box, Button, Card, CardContent, CardHeader, TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CourseCard from 'components/molecules/CourseCard';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { useFirestore, useFirestoreCollectionData, useFirestoreDocData, useFunctions } from 'reactfire';

type CourseBuyerProps = {};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  verificationRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonContainer: {
    marginTop: theme.spacing(3),
  },
  coursesList: {
    display: 'flex',
    justifyContent: 'space-around',
  },
}));

const CourseBuyer: React.FC<CourseBuyerProps> = () => {
  const classes = useStyles();
  const firestore = useFirestore();
  const functions = useFunctions();
  const [userProfile] = useUserProfile();
  const appContext = useContext(AppContext);
  const usersRef = collection(firestore, USERS);
  const couresRef = collection(firestore, COURSES);
  const buyRequest = httpsCallable(functions, BUY_COURSE_REQUEST);
  const { data: allCourses } = useFirestoreCollectionData(couresRef, { idField: 'id' });
  const configRef = doc(firestore, COLLECTION_CONFIG, COLLECTION_COURSE_CONFIG);
  const { data: configData } = useFirestoreDocData(configRef);
  const buyCourseCallbackFunction = httpsCallable(functions, BUY_COURSE_CONFIRMATION_CALLBACK);
  const [courses, setCourses] = useState<Course[]>([]);
  const [loading, setLoading] = useState(false);
  const [verificatinoInProgress, setVerificatinoInProgress] = useState(false);
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const [currentTransaction, setCurrentTransaction] = useState<Transaction | undefined>();
  const [status, setStatus] = useState('Payment In Progress');
  const { data: userCourses, status: historyStatus } = useFirestoreCollectionData(
    collection(doc(usersRef, appContext.loggedInUserId), COURSES),
    {
      idField: 'id',
    },
  );
  const [selectedPlan, setSelectedPlan] = useState(PREMIUM_PLAN);
  useEffect(() => {
    const filteredCourses = (allCourses || []).filter(
      (course) => course.ageGroup === userProfile?.kidsAgeGroup && course.planType === selectedPlan,
    );
    setCourses(filteredCourses as Course[]);
  }, [allCourses, selectedPlan, userProfile?.kidsAgeGroup]);
  const updateTransaction = (req: RazorpayConfirmationCallBack) => {
    setVerificatinoInProgress(true);

    setStatus('Verifying');
    buyCourseCallbackFunction(req)
      .then(() => {
        setStatus('Payment Received!');
        setVerificatinoInProgress(false);
        setLoading(false);
      })
      .catch(() => {
        setStatus('Payment Verification Failed!');
        setVerificatinoInProgress(false);
        setLoading(false);
      });
  };
  const handleBuy = (course: Course) => {
    setLoading(true);
    const req: BuyCourseRequest = {
      courseId: course.id,
      amount: course.sellingPrice,
    };
    buyRequest(req)
      .then((resp) => {
        const transaction = resp.data as Transaction;
        setPaymentInProgress(true);
        setCurrentTransaction(transaction);
        const options = {
          key: 'rzp_live_DsFLWnV9lUGQWe', // Enter the Key ID generated from the Dashboard
          amount:
            (!!transaction && !!transaction.razorpayOrder && !!transaction.razorpayOrder.amount_due
              ? transaction.razorpayOrder.amount_due
              : 100) / 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: 'INR',
          name: 'Octorun',
          description: 'Buy Course ' + course.title,
          // "image": "https://example.com/your_logo",
          order_id: transaction.razorpayOrder?.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          //   callback_url: 'https://us-central1-codebee-281912.cloudfunctions.net/buyCourseCallback',
          handler: function (response: any) {
            updateTransaction(response as RazorpayConfirmationCallBack);
          },
          prefill: {
            name: userProfile?.name,
            email: userProfile?.email,
            contact: userProfile?.phoneNumber,
          },
          notes: {
            address: userProfile?.address || 'No Address',
          },
        };
        new (window as any).Razorpay(options).open();
      })
      .catch((err) => alert(err))
      .finally(() => setLoading(false));
  };
  const purchasedCourses = (userCourses || []).map((history) => history.id);
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <div className={classes.root}>
        {loading && <LoadingSpinner />}
        {!loading && paymentInProgress && currentTransaction ? (
          <Section title={'Transaction Id:' + currentTransaction.id}>
            <div className={classes.verificationRoot}>
              <LabelValue label="Order Id" value={currentTransaction.razorpayOrder?.id || ''} />
              <LabelValue label="Amount" value={currentTransaction.razorpayOrder?.amount_due || ''} />
              <LabelValue label="Status" value={status} />
              <div className={classes.buttonContainer}>
                {loading ? (
                  <LoadingSpinner />
                ) : verificatinoInProgress ? (
                  <LoadingSpinner>Verifying</LoadingSpinner>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setCurrentTransaction(undefined);
                      setVerificatinoInProgress(false);
                    }}
                  >
                    Go Back
                  </Button>
                )}
              </div>
            </div>
          </Section>
        ) : (
          !loading && (
            <Card>
              <CardHeader
                title={
                  <Box width="40%">
                    <TextField
                      id="ageGroup"
                      label="Select a plan"
                      select
                      value={selectedPlan}
                      onChange={(event) => setSelectedPlan(event.target.value)}
                      name="planType"
                      fullWidth
                    >
                      {[ECONOMY_PLAN, PREMIUM_PLAN].map((item) => (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      ))}
                    </TextField>
                  </Box>
                }
                subheader="Select a course"
              />
              <CardContent className={classes.coursesList}>
                {courses.map((course) => (
                  <CourseCard
                    course={course}
                    key={course.id}
                    recommended={course.id === configData[userProfile?.kidsAgeGroup + '_' + selectedPlan]}
                    purchased={purchasedCourses.includes(course.id)}
                    onBuyClicked={() => handleBuy(course)}
                  />
                ))}
              </CardContent>
            </Card>
          )
        )}
      </div>
    </Suspense>
  );
};

export default CourseBuyer;
