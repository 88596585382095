import { connectFirestoreEmulator, getFirestore } from '@firebase/firestore';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { AuthProvider, FirebaseAppProvider, FirestoreProvider, FunctionsProvider, useFirebaseApp } from 'reactfire';
import { CoreLayout, LoadingSpinner, LoginPage, PageNotFoundPage } from '../components';
import { LOGIN_ROUTE, REGISTER_ROUTE } from '../components/pages/routes';
import { RouteModel } from '../models';
import './App.css';
import AuthCheck from './AuthCheck';

const firebaseConfig =
  process.env.NODE_ENV === 'test'
    ? {
        apiKey: 'AIzaSyDve9GsEnpqsjUOPZAeBM_TL9D0ClEOKdg',
        authDomain: 'octorun-test.firebaseapp.com',
        projectId: 'octorun-test',
        storageBucket: 'octorun-test.appspot.com',
        messagingSenderId: '847086678101',
        appId: '1:847086678101:web:3f0ff2c17da50bc31424dc',
        measurementId: 'G-83CZ32302C',
      }
    : {
        apiKey: 'AIzaSyDLG9Gr26FfAXvk8FGImQnwJ4lwPBNq814',
        authDomain: 'codebee-281912.firebaseapp.com',
        databaseURL: 'https://codebee-281912.firebaseio.com',
        projectId: 'codebee-281912',
        storageBucket: 'codebee-281912.appspot.com',
        messagingSenderId: '1076870891903',
        appId: '1:1076870891903:web:a10c59430947545ae9724a',
        measurementId: 'G-K1J8BEYX5W',
      };
export type AppProps = {
  authenticatedRoutes: RouteModel[];
  registerPage?: React.ComponentType<any>;
  loginPage?: React.ComponentType<any>;
};

const FirebaseWrapperApp: React.FC<AppProps> = ({ authenticatedRoutes, registerPage, loginPage = LoginPage }) => {
  const app = useFirebaseApp();
  const fireStore = getFirestore(app);
  const auth = getAuth(app);
  const func = getFunctions(app);

  if (process.env.NODE_ENV === 'test') {
    // Set up emulators
    connectFirestoreEmulator(fireStore, 'localhost', 8080);
    connectAuthEmulator(auth, 'http://localhost:9099');
    connectFunctionsEmulator(func, 'localhost', 5001);
  }
  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={fireStore}>
        <FunctionsProvider sdk={func}>
          <Router>
            <Suspense fallback={<LoadingSpinner size={32} />}>
              <Switch>
                <Route path={LOGIN_ROUTE} component={loginPage} />
                {registerPage && <Route path={REGISTER_ROUTE} component={registerPage} />}
                <AuthCheck fallback={<Redirect to={LOGIN_ROUTE} />}>
                  <CoreLayout routes={authenticatedRoutes} />
                  <Route path="/" exact>
                    <Redirect to={authenticatedRoutes[0].path} />
                  </Route>
                </AuthCheck>
                <Route path="/" exact>
                  <Redirect to={LOGIN_ROUTE} />
                </Route>
                <Route path="*" component={PageNotFoundPage} />
              </Switch>
            </Suspense>
          </Router>
        </FunctionsProvider>
      </FirestoreProvider>
    </AuthProvider>
  );
};
const App: React.FC<AppProps> = (props) => {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirebaseWrapperApp {...props} />
    </FirebaseAppProvider>
  );
};

export default App;
