import { COURSES } from '@codebee/constants/collections';
import { LabelValue, LoadingSpinner, TopToolbar } from '@codebee/core/src/components';
import { useUserProfile } from '@codebee/core/src/hooks';
import { Course, StudentLearningHistoryEntry } from '@codebee/models';
import { collection, doc } from '@firebase/firestore';
import { Card, CardContent, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Note } from '@material-ui/icons';
import CompletionStatus from 'components/atoms/CompletionStatus';
import SessionDetails from 'components/organisms/courses/SessionDetails';
import React, { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { useFirestore, useFirestoreCollectionData, useFirestoreDocDataOnce } from 'reactfire';
type CoursePageProps = {};

const useStyles = makeStyles((theme: Theme) => ({
  sessionTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  listContainer: {
    marginRight: theme.spacing(2),
  },
}));

const CoursePage: React.FC<CoursePageProps> = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const coursesRef = doc(useFirestore(), COURSES, id);
  const { data: course, status } = useFirestoreDocDataOnce(coursesRef, {
    idField: 'id',
  });

  const [userProfile, profile, complete, userDocRef] = useUserProfile();
  const { data: userCourseHistory } = useFirestoreCollectionData(collection(userDocRef, COURSES), {
    idField: 'id',
  });

  const [profileCourse, setProfileCourse] = React.useState<StudentLearningHistoryEntry | undefined>();
  React.useEffect(() => {
    if (userCourseHistory && course) {
      if (userCourseHistory) {
        const myCourse = userCourseHistory.find((ownedCourse) => ownedCourse.id === course.id);
        setProfileCourse(myCourse as StudentLearningHistoryEntry);
      }
    }
  }, [course, userProfile]);
  const [selectedSession, setSelectedSession] = React.useState(0);
  return (
    <>
      <TopToolbar leftContent={<Typography variant="h5">Courses Details</Typography>} showBackButton />

      <Suspense fallback={<LoadingSpinner />}>
        {!profileCourse ? (
          <Typography variant="h3">You cannot view this course</Typography>
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography color="textSecondary" gutterBottom variant="h4">
                    {course.title}
                    <CompletionStatus
                      isComplete={profileCourse.isComplete}
                      text={profileCourse.isComplete ? 'Complete' : 'In Progress'}
                    />
                  </Typography>
                  <LabelValue label="Description" value={course.description} />
                </CardContent>
              </Card>
            </Grid>
            <Typography variant="subtitle2" className={classes.sessionTitle}>
              Sessions
            </Typography>
            <Grid item xs={12} container>
              <Grid item xs={6}>
                <List className={classes.listContainer}>
                  {(course as Course).sessions.map((_session, index) => {
                    const completedSessionIndex = profileCourse.completedSessionIndex;
                    const isComplete = completedSessionIndex > -1 && completedSessionIndex <= selectedSession;
                    return (
                      <ListItem button selected={selectedSession === index} onClick={() => setSelectedSession(index)}>
                        <ListItemIcon>
                          <Note />
                        </ListItemIcon>
                        <ListItemText
                          primary={index + 1 + ' - ' + course.sessions[index].title}
                          secondary={
                            <CompletionStatus isComplete={isComplete} text={isComplete ? 'Complete' : 'Not Complete'} />
                          }
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
              <Grid item xs={6}>
                <SessionDetails
                  session={course.sessions[selectedSession]}
                  id={selectedSession}
                  hideDetails={selectedSession > profileCourse.completedSessionIndex}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Suspense>
    </>
  );
};

export default CoursePage;
