import { IconButton, Toolbar } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';

export type TopToolbarProps = {
  leftContent?: React.ReactNode | React.ReactNodeArray;
  rightContent?: React.ReactNode | React.ReactNodeArray;
  showBackButton?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // marginLeft: -theme.spacing(2.5),
    // marginRight: -theme.spacing(2.5),
    display: 'block',
  },
  contents: {
    display: 'flex',
    alignItems: 'center',
  },
  spacing: {
    flexGrow: 1,
  },
  container: {
    '&>*': {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    '&>:first-child': {
      marginLeft: 0,
    },
    '&>:last-child': {
      marginRight: 0,
    },
  },
}));

const TopToolbar: React.FC<TopToolbarProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };
  return (
    <Toolbar
      classes={{
        root: classes.root,
      }}
      disableGutters
    >
      <div className={classes.contents}>
        {props.showBackButton && (
          <IconButton onClick={goBack}>
            <ArrowBack />
          </IconButton>
        )}
        <div className={classes.container}>{props.leftContent}</div>
        <div className={classes.spacing} />
        <div className={classes.container}>{props.rightContent}</div>
      </div>
    </Toolbar>
  );
};

export default TopToolbar;
