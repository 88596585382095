import { COURSE_MATERIALS } from '@codebee/constants';
import { doc } from '@firebase/firestore';
import { Paper } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useFirestore } from 'reactfire';

type CourseMaterialViewerProps = {
  id: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddig: theme.spacing(1),
  },
}));

const CourseMaterialViewer: React.FC<CourseMaterialViewerProps> = (props) => {
  const classes = useStyles();
  const firestore = useFirestore();
  const courseMaterialRef = doc(firestore, COURSE_MATERIALS, props.id);
  // const { data: document, error, status } = useFirestoreDocDataOnce<CourseMaterial>(courseMaterialRef.doc(props.id));
  return (
    <Paper className={classes.root}>
      {/* {status==='loading'?<LoadingSpinner />:null}
      <Suspense fallback={}>
        {document && document.document ? (
          <ReactMarkdown source={document.document} />
        ) : (
          'Sorry the document no longer exist; Contact Help Desk'
        )}
      </Suspense> */}
    </Paper>
  );
};

export default CourseMaterialViewer;
