import React from "react";
import { ListItem, ListItemIcon, ListItemText, Link, Icon } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useLocation, NavLink as RouterLink } from "react-router-dom";
import { SvgIconComponent } from "@material-ui/icons";
const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
  },
  active: {
    color: theme.palette.primary.main,
    background: "#eae3e3",
  },
  icon: {
    color: "#bda8c1",
    marginTop: 0,
  },
  activeIcon: {
    color: theme.palette.primary.main,
  },
}));

export type NavLinkProps = {
  path: string;
  text: string;
  icon: string;
  exact?: boolean;
};
const NavLink: React.FC<NavLinkProps> = ({
  path,
  text,
  icon,
  exact = false,
}) => {
  const classes = useStyles();

  const location = useLocation();
  const isCurrentPath = exact
    ? location.pathname === path
    : location.pathname.startsWith(path.toString());
  return (
    <Link component={RouterLink} to={path}>
      <ListItem
        button
        alignItems="flex-start"
        className={clsx(classes.listItem, isCurrentPath && classes.active)}
      >
        <ListItemIcon
          className={clsx(classes.icon, isCurrentPath && classes.activeIcon)}
        >
          <Icon>{icon}</Icon>
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    </Link>
  );
};
export default NavLink;
