import { CAMPS } from '@codebee/constants';
import { CenteredMessagePanel, PageShell } from '@codebee/core';
import { Camp } from '@codebee/models';
import { collection, query, where } from '@firebase/firestore';
import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CampCard from 'components/molecules/CampCard';
import React from 'react';
import { useHistory } from 'react-router';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

export type CampBuyerPageProps = {};

const useStyles = makeStyles((theme: Theme) => ({}));

const CampBuyerPage: React.FC<CampBuyerPageProps> = (props) => {
  const classes = useStyles();
  const firestore = useFirestore();
  const history = useHistory();
  const activeCampsQuery = query(collection(firestore, CAMPS), where('open', '==', true));
  const { data: campsData, status: campsLoading } = useFirestoreCollectionData(activeCampsQuery, { idField: 'id' });

  const activeCamps = (campsData || []) as Camp[];

  return (
    <PageShell title="Enroll in Camp" loading={campsLoading === 'loading'} showBackButton>
      {activeCamps.length === 0 ? (
        <CenteredMessagePanel message="Sorry! No Camps scheduled at the moment." />
      ) : (
        <Box display="flex" justifyContent="space-around">
          {activeCamps.map((camp) => (
            <CampCard
              key={camp.id}
              camp={camp}
              onBuyComplete={() => {
                history.replace('/dashboard');
              }}
            />
          ))}
        </Box>
      )}
    </PageShell>
  );
};

export default CampBuyerPage;
