import { Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FirebasePhoneAuth from 'components/molecules/FirebasePhoneAuth';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../octorunLogo.png';
import '../register/register.css';
type RegisterProps = {};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logoContainer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
  },
  nameConatiner: {},
  otherLink: {
    marginTop: theme.spacing(2),
    '&>*': {
      display: 'inline-flex',
    },
  },
}));

const LoginPage: React.FC<RegisterProps> = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.logoContainer}>
        <img src={logo} alt="octorun" />
      </div>
      <FirebasePhoneAuth signInSuccessUrl="/" />
      <div className={classes.otherLink}>
        <Typography variant="body1" color="initial" style={{ paddingRight: '10px' }}>
          New user?
        </Typography>
        <Link to="/register">
          <Typography variant="subtitle2" color="primary">
            Register
          </Typography>
        </Link>
      </div>
    </div>
  );
};

export default LoginPage;
