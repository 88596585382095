export const AGE_GROUP_SIX_TO_EIGHT = '6-8 Years';
export const AGE_GROUP_NINE_TO_ELEVEN = '9-11 Years';
export const AGE_GROUP_TWELEVE_TO_FOURTEEN = '12-14 Years';
export const AGE_GROUP_ALL = 'All age group';

export const AGE_GROUPS = [
  AGE_GROUP_ALL,
  AGE_GROUP_SIX_TO_EIGHT,
  AGE_GROUP_NINE_TO_ELEVEN,
  AGE_GROUP_TWELEVE_TO_FOURTEEN,
];
