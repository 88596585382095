import { SLOTS, SLOT_MISSED, SLOT_STARTED } from '@codebee/constants';
import { LoadingSpinner } from '@codebee/core';
import { useUserProfile } from '@codebee/core/src/hooks';
import { Slot } from '@codebee/models';
import { doc } from '@firebase/firestore';
import { Button, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useFirestore, useFirestoreDocData } from 'reactfire';
type SlotActionProps = {
  slotId: string | null | undefined;
  onSchedule: () => void;
  onStart: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  message: {
    marginBottom: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
}));

const SlotAction: React.FC<SlotActionProps> = (props) => {
  const classes = useStyles();
  const slotRef = doc(useFirestore(), SLOTS, props.slotId || '');

  const { data: slot, status } = useFirestoreDocData(slotRef, { idField: 'id' });
  if (status === 'loading') {
    return <LoadingSpinner />;
  }
  if (!slot) {
    return renderSlotBookAction();
  } else {
    return <BookedSlotAction slot={slot as Slot} onStart={props.onStart} onSchedule={props.onSchedule} />;
  }

  function renderSlotBookAction(): React.ReactElement<any, any> | null {
    return (
      <div className={classes.root}>
        <Typography variant="subtitle1" className={classes.message}>
          You have not booked next class
        </Typography>
        <Button variant="contained" color="primary" onClick={() => props.onSchedule()}>
          Book Class
        </Button>
      </div>
    );
  }
};

export default SlotAction;

type BookedSlotActionProps = {
  slot: Slot;
  onSchedule: () => void;
  onStart: () => void;
};
const BookedSlotAction: React.FC<BookedSlotActionProps> = ({ slot, onSchedule, onStart }) => {
  const classes = useStyles();
  const [userProfile] = useUserProfile();

  if (slot.status === SLOT_STARTED) {
    return (
      <div className={classes.root}>
        <Typography variant="subtitle1" className={classes.message}>
          Class has already begun
        </Typography>
        <Button variant="contained" color="primary" onClick={() => onStart()}>
          Join Class
        </Button>
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" className={classes.message}>
        {userProfile?.nextSessionStatus === SLOT_MISSED
          ? 'You missed the class! Please reschedule'
          : 'Please wait for Class to begin'}
      </Typography>
      {userProfile?.nextSessionStatus === SLOT_MISSED && (
        <Button variant="contained" color="primary" onClick={() => onSchedule()}>
          Reschedule
        </Button>
      )}
    </div>
  );
};
