import { USERS } from '@codebee/constants';
import { LoadingSpinner } from '@codebee/core';
import AppContext from '@codebee/core/src/app/AppContext';
import { UserProfile } from '@codebee/models';
import { doc, setDoc } from '@firebase/firestore';
import { AppBar, Box, Button, Card, CardContent, CardHeader, Tab, Tabs } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useContext, useState } from 'react';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import CampDashboard from './CampDashboard';
import CourseDashboardPage from './CourseDashboardPage';

export type DashboardPageProps = {};

const useStyles = makeStyles((theme: Theme) => ({}));

const DashboardPage: React.FC<DashboardPageProps> = (props) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const appContext = useContext(AppContext);
  const firestore = useFirestore();
  const userRef = doc(firestore, USERS, appContext.loggedInUserId);
  const { data, status } = useFirestoreDocData(userRef, { idField: 'id' });
  const renderBasicInfoForm = () => {
    return (
      <Card>
        <CardHeader title="Complete your profile" />
        <CardContent>
          <Formik
            initialValues={userProfile}
            validate={(values) => {
              const errors: Partial<UserProfile> = {};
              if (!values.name) {
                errors.name = 'Enter your name.';
              }
              if (!values.kidsName) {
                errors.kidsName = 'Enter Kids Name';
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              // setSubmitting(true);
              setDoc(userRef, { kidsName: values.kidsName, name: values.name }, { merge: true }).finally(() =>
                setSubmitting(false),
              );
            }}
          >
            {({ submitForm, isSubmitting }) => (
              <Form>
                <div>
                  <Field component={TextField} name="name" label="Parents Name" helperText="" fullWidth />
                  <Field component={TextField} name="kidsName" label="Kid's Name" helperText="" fullWidth />
                  <Box mt={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={submitForm}
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                  </Box>
                </div>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    );
  };

  if (status === 'loading') {
    return (
      <Box>
        <LoadingSpinner />
      </Box>
    );
  }
  const userProfile = data as UserProfile;

  return (
    <Box>
      {!userProfile.kidsName ? (
        renderBasicInfoForm()
      ) : (
        <Box>
          <AppBar position="static">
            <Tabs value={selectedTab} onChange={(_, value) => setSelectedTab(value)}>
              <Tab label="Camps" />
              <Tab label="Classes" />
            </Tabs>
          </AppBar>
          <Box>{selectedTab === 0 ? <CampDashboard userProfile={userProfile} /> : <CourseDashboardPage />}</Box>
        </Box>
      )}
    </Box>
  );
};

export default DashboardPage;
