import { makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  noGap: {
    margin: 0,
  },
  label: {
    marginBottom: theme.spacing(0.5),
  },
  horizontalLabelValue: {
    display: 'flex',
    flexDirection: 'row',
  },
  horizontalLabel: {
    width: '55%',
    maxWidth: theme.spacing(13),
  },
  horizontalValue: {
    textTransform: 'capitalize',
  },
}));
const LabelValue: React.FC<LabelValueProps> = ({ label, value, noGap = false, horizontal = false }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, noGap && classes.noGap)}>
      {!horizontal ? (
        <>
          <Typography variant="caption" component="div" className={classes.label}>
            {label}
          </Typography>
          <Typography variant="subtitle1">{`${value}`}</Typography>
        </>
      ) : (
        <div className={classes.horizontalLabelValue}>
          <Typography className={classes.horizontalLabel} variant="body2">
            {label}
          </Typography>
          <Typography className={classes.horizontalValue} variant="body1">{`${value}`}</Typography>
        </div>
      )}
    </div>
  );
};
export default LabelValue;
export type LabelValueProps = {
  label: string;
  value: string | number | undefined;
  noGap?: boolean;
  horizontal?: boolean;
};
