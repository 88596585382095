import { Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import clsx from 'clsx';
import React from 'react';
type CompletionStatusProps = {
  isComplete: boolean;
  text: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  completed: {
    color: theme.palette.success.main,
  },
  inProgress: {
    color: theme.palette.info.main,
  },
  iconContainer: {
    paddingRight: theme.spacing(1),
  },
  root: {
    display: 'flex',
    margin: theme.spacing(1),
    marginLeft: 0,
    alignItems: 'center',
  },
}));

const CompletionStatus: React.FC<CompletionStatusProps> = ({ isComplete, text }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, isComplete && classes.completed)}>
      <div className={classes.iconContainer}>{isComplete ? <CheckCircleIcon /> : <WatchLaterIcon />}</div>
      <Typography variant="subtitle1">{text}</Typography>
    </div>
  );
};

export default CompletionStatus;
