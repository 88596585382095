import { USERS } from '@codebee/constants';
import { UserProfile } from '@codebee/models';
import { doc, DocumentReference, setDoc } from '@firebase/firestore';
import { useContext, useEffect, useState } from 'react';
import { useFirestore } from 'reactfire';
import { docData } from 'rxfire/firestore';
import AppContext from '../app/AppContext';

const useUserProfile = (): [UserProfile | null, (data: Partial<UserProfile>) => void, boolean, DocumentReference] => {
  const appContext = useContext(AppContext);
  const firestore = useFirestore();
  const userDocRef = doc(firestore, USERS, appContext.loggedInUserId);
  const [profileComplete, setProfileComplete] = useState(false);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  // const { data: userProfile }: UserProfile = useFirestoreDocData>(userDocRef, { idField: 'id' });
  const updateProfile = (data: Partial<UserProfile>) => {
    setDoc(userDocRef, data, { merge: true });
  };
  useEffect(() => {
    docData(userDocRef, { idField: 'uid' }).subscribe((userData) => {
      const profile = userData as UserProfile;
      setProfileComplete(!!profile && !!profile.kidsAgeGroup);
      setUserProfile(profile);
    });
  }, []);
  return [userProfile, updateProfile, profileComplete, userDocRef];
};
export default useUserProfile;
