import { RouteModel } from './../../models/routeModels';
import { DashboardPage } from './dashboard/DashboardPage';
import { HelpPage } from './help/HelpPage';

export const DASHBOARD_PAGE_ROUTE = '/dashboard';
export const LOGIN_ROUTE = '/login';
export const REGISTER_ROUTE = '/register';

export const HELP_PAGE_ROUTE = '/help';
export const authenticatedRoutes: RouteModel[] = [
  {
    path: DASHBOARD_PAGE_ROUTE,
    component: DashboardPage,
    exact: true,
    navRoute: {
      icon: 'home',
      exact: true,
      title: 'Dasshboard',
    },
  },
  {
    path: HELP_PAGE_ROUTE,
    component: HelpPage,
    exact: true,
    navRoute: {
      title: 'HelpDesk',
      icon: 'help',
    },
  },
];
