import { CAMPS } from '@codebee/constants';
import { CenteredMessagePanel } from '@codebee/core';
import AppContext from '@codebee/core/src/app/AppContext';
import { Camp, UserProfile } from '@codebee/models';
import { collection, query, where } from '@firebase/firestore';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CampDetails from 'components/organisms/camps/CampDetails';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { CAMP_BUYER } from '../routes';

export type CampDashboardProps = {
  userProfile: UserProfile;
};

const useStyles = makeStyles((theme: Theme) => ({}));

const CampDashboard: React.FC<CampDashboardProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const firestore = useFirestore();
  const appContext = useContext(AppContext);
  const myCampsQuery = query(
    collection(firestore, CAMPS),
    where('active', '==', true),
    where('studentIds', 'array-contains', appContext.loggedInUserId),
  );

  const { data, status } = useFirestoreCollectionData(myCampsQuery);
  if (status === 'loading') {
    return <CenteredMessagePanel message="Loading..." />;
  }
  const myCamps = (data as unknown as Camp[]) || [];
  if (myCamps.length === 0) {
    return (
      <CenteredMessagePanel
        message="You have not signed up for a camp"
        actionText="Enroll Now"
        onActionClicked={() => {
          history.push(CAMP_BUYER);
        }}
      />
    );
  } else {
    return <CampDetails camp={myCamps[0]} />;
  }
  // const {data:myCamps,status:campsLoadingStatus}=useFirestoreCollectionData(myCampsQuery);
  // if(campsLoadingStatus==='loading'){
  //   return <LoadingSpinner/>
  // }
  // return <div>CampDashboard</div>;
};

export default CampDashboard;
