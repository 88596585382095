import { TRIAL_COURSE, TRIAL_SLOT, USERS } from '@codebee/constants';
import { UserProfile } from '@codebee/models';
import { collection, doc, setDoc } from '@firebase/firestore';
import { Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FirebasePhoneAuth from 'components/molecules/FirebasePhoneAuth';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useFirestore } from 'reactfire';
import logo from '../../../octorunLogo.png';
import './register.css';
type RegisterProps = {};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logoContainer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
  },
  nameConatiner: {},
  otherLink: {
    marginTop: theme.spacing(2),
    '&>*': {
      display: 'inline-flex',
    },
  },
}));

const Register: React.FC<RegisterProps> = (props) => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const usersRef = collection(useFirestore(), USERS);
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const history = useHistory();
  const [profileCreationInProgress, setProfileCreationInProgress] = useState(false);
  const [alreadyRegisteredError, setAlreadyRegisteredError] = useState('');
  const updateUser = (userCredentials: any) => {
    setAlreadyRegisteredError('');
    if (userCredentials) {
      console.log('Result ', userCredentials);
      if (!userCredentials['_tokenResponse'].isNewUser) {
        setAlreadyRegisteredError('You have already registered!. Signing in...');
        setTimeout(() => {
          history.replace('/');
        }, 1000);
      } else {
        const userProfile: Partial<UserProfile> = {
          name: name,
          phoneNumber: userCredentials.user.phoneNumber,
          plan: TRIAL_SLOT,
          planType: TRIAL_SLOT,
          courseId: TRIAL_COURSE,
        };
        setProfileCreationInProgress(true);
        setTimeout(() => {
          setDoc(doc(usersRef, userCredentials.user.uid), userProfile, { merge: true }).then(() => {
            setProfileCreationInProgress(false);
            history.replace('/');
          });
        }, 3000);

        return false;
      }
    }
    return false;
  };
  return (
    <div className={classes.root}>
      <div className={classes.logoContainer}>
        <img src={logo} alt="octorun" />
      </div>
      <TextField
        label="Parents Name"
        value={name}
        required
        style={{ width: 310 }}
        type="outlined"
        onChange={(event) => setName(event.target.value)}
        disabled={showPhoneNumber}
      ></TextField>
      <br />
      {!showPhoneNumber ? (
        <Button variant="contained" color="primary" disabled={!name} onClick={() => setShowPhoneNumber(true)}>
          Next
        </Button>
      ) : (
        !profileCreationInProgress && <FirebasePhoneAuth signInSuccessUrl="/" signedInCallBack={updateUser} />
      )}
      {profileCreationInProgress && (
        <>
          <Typography variant="body1" color="initial" style={{ paddingRight: '10px' }}>
            Creating your profile...
          </Typography>
          <CircularProgress />
        </>
      )}
      {!alreadyRegisteredError ? (
        <div className={classes.otherLink}>
          <Typography variant="body1" color="initial" style={{ paddingRight: '10px' }}>
            Already have an account?
          </Typography>
          <Link to="/login">
            <Typography variant="subtitle2" color="primary">
              Login
            </Typography>
          </Link>
        </div>
      ) : (
        <Typography variant="body1" color="primary" style={{ paddingRight: '10px' }}>
          {alreadyRegisteredError}
        </Typography>
      )}
    </div>
  );
};

export default Register;
