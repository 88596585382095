import React, { useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Slot } from '@codebee/models';
import { InvertedLabel } from '@codebee/core';
import { COLORS } from '@codebee/core/src/defaultTheme';
import { Grid, Paper } from '@material-ui/core';
import moment from 'moment';
import clsx from 'clsx';
type SlotSelectorProps = {
  allSlots: Slot[];
  selectedDate: Date;
  selectedSlot: Slot | null;
  onSlotSelected: (slot: Slot | null) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: theme.spacing(70),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(2),
  },
  timeSelector: {
    // maxWidth: theme.spacing(10),
    padding: theme.spacing(1),
    textAlign: 'center',
    background: COLORS.lightGrey,
    color: '#000',
    cursor: 'pointer',
    border: '1px solid ' + COLORS.lightGrey,
    borderRadius: theme.spacing(0.5),
  },
  timeSelectorSelected: {
    color: COLORS.cherryRed,
    background: theme.palette.common.white,
    borderColor: COLORS.cherryRed,
  },
  timeSelectorDisabled: {
    color: COLORS.lightGreyText,
    cursor: 'not-allowed',
  },
  contents: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    marginTop: 0,
    // display: 'flex',
    color: COLORS.white,
    width: '100%',
    // justifyContent: 'space-evenly',
    // borderRadius: theme.spacing(0.5),
  },
}));

const allTimeSlots = [
  '12:00 am',
  '01:00 am',
  '02:00 am',
  '03:00 am',
  '04:00 am',
  '05:00 am',
  '06:00 am',
  '07:00 am',
  '08:00 am',
  '09:00 am',
  '10:00 am',
  '11:00 am',
  '12:00 pm',
  '01:00 pm',
  '02:00 pm',
  '03:00 pm',
  '04:00 pm',
  '05:00 pm',
  '06:00 pm',
  '07:00 pm',
  '08:00 pm',
  '09:00 pm',
  '10:00 pm',
  '11:00 pm',
];
const SlotSelector: React.FC<SlotSelectorProps> = ({ allSlots, selectedDate, onSlotSelected, selectedSlot }) => {
  const classes = useStyles();
  const [grouppedSlots, setGrouppedSlots] = useState<{ [key: string]: Slot[] }>({});
  const [timeSlots, setTimeSlots] = useState<{ [key: string]: Slot[] }>({});

  useEffect(() => {
    const _grouppedSlots: { [key: string]: Slot[] } = {};
    allSlots.forEach((slot) => {
      const dateKey = moment(slot.startTime.toDate()).format('DD MM yyyy');
      if (!_grouppedSlots[dateKey]) {
        _grouppedSlots[dateKey] = [];
      }
      _grouppedSlots[dateKey].push(slot);
    });
    setGrouppedSlots(_grouppedSlots);
  }, [allSlots]);
  useEffect(() => {
    const selectedDateKey = moment(selectedDate).format('DD MM yyyy');
    const slotsByTime: { [key: string]: Slot[] } = {};
    (grouppedSlots[selectedDateKey] || []).forEach((slot) => {
      const timeKey = moment(slot.startTime.toDate()).format('hh:mm a');
      if (!slotsByTime[timeKey]) {
        slotsByTime[timeKey] = [];
      }
      slotsByTime[timeKey].push(slot);
    });
    setTimeSlots(slotsByTime);
  }, [selectedDate, grouppedSlots]);
  return (
    <div className={classes.root}>
      <InvertedLabel value="Choose Slot"></InvertedLabel>
      <Paper className={classes.contents}>
        <Grid container spacing={2}>
          {allTimeSlots.map((timeKey) => {
            const enabled = !!timeSlots[timeKey];
            const selected = !selectedSlot
              ? false
              : moment(selectedSlot.startTime.toDate()).format('hh:mm a') === timeKey;
            return (
              <Grid item key={timeKey} xs={3}>
                <div
                  className={clsx(
                    classes.timeSelector,
                    !enabled && classes.timeSelectorDisabled,
                    selected && classes.timeSelectorSelected,
                  )}
                  onClick={() => enabled && onSlotSelected(timeSlots[timeKey][0])}
                >
                  {timeKey}
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Paper>
    </div>
  );
};

export default SlotSelector;
