import { BUY_CAMP_CONFIRMATION_CALLBACK, BUY_CAMP_REQUEST, USERS } from '@codebee/constants';
import { LoadingSpinner } from '@codebee/core';
import AppContext from '@codebee/core/src/app/AppContext';
import { BuyCampRequest, RazorpayConfirmationCallBack, Transaction } from '@codebee/models';
import { httpsCallable } from '@firebase/functions';
import { Box, Button, CircularProgress, Modal, Paper, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { UserProfile } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { env } from 'process';
import React, { useContext, useState } from 'react';
import { useFirestore, useFunctions } from 'reactfire';

export type PaymentButtonProps = {
  amount: number;
  title: string;
  courseId: string;
  onCompletion: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  modalBody: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
}));
export const razorpayKeys = {
  live: 'rzp_live_DsFLWnV9lUGQWe',
  test: 'rzp_test_Bxekvwu2lm4fXt',
};

const PaymentButton: React.FC<PaymentButtonProps> = ({ amount, onCompletion, courseId, title }) => {
  const classes = useStyles();
  const functions = useFunctions();
  const firestore = useFirestore();
  const buyRequest = httpsCallable(functions, BUY_CAMP_REQUEST);
  const buyCourseCallbackFunction = httpsCallable(useFunctions(), BUY_CAMP_CONFIRMATION_CALLBACK);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [verificationInProgress, setverificationInProgress] = useState(false);
  const [status, setStatus] = useState('');
  const [currentTransaction, setCurrentTransaction] = useState<Transaction | undefined>();
  const [open, setOpen] = useState(false);
  const [isTransactionComplete, setIsTransactionComplete] = useState(false);
  const appContext = useContext(AppContext);
  const updateTransaction = (req: RazorpayConfirmationCallBack) => {
    setverificationInProgress(true);
    setOpen(true);
    setStatus('Verifying');
    buyCourseCallbackFunction(req)
      .then(() => {
        setStatus('Payment Received!');
      })
      .catch((err) => {
        setStatus('Payment Verification Failed!');
      })
      .finally(() => {
        setIsTransactionComplete(true);
        setverificationInProgress(false);
      });
  };
  // const handleBuy = () => {
  //   setOpen(true);
  // };
  const handleBuy = async () => {
    const request: BuyCampRequest = {
      campId: courseId,
      amount: amount,
      title: title,
    };
    setRequestInProgress(true);
    const profileDoc = await getDoc(doc(firestore, USERS, appContext.loggedInUserId));
    const userProfile = profileDoc.data() as unknown as UserProfile;
    buyRequest(request)
      .then((resp) => {
        const transaction = resp.data as unknown as Transaction;
        setCurrentTransaction(transaction);
        const options = {
          key: env.NODE_ENV === 'test' ? razorpayKeys.test : razorpayKeys.live, // Enter the Key ID generated from the Dashboard
          amount: transaction.razorpayOrder?.amount_due, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: 'INR',
          name: 'Octorun',
          description: title,
          prefill: {
            name: userProfile.name || '',
            email: userProfile.email || '',
            contact: userProfile.phoneNumber || '',
          },
          order_id: transaction.razorpayOrder?.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          handler: function (response: any) {
            updateTransaction(response as RazorpayConfirmationCallBack);
          },
        };
        new (window as any).Razorpay(options).open();
      })
      .catch((err) => {
        setStatus('Failed to make request');
      })
      .finally(() => {
        setRequestInProgress(false);
      });
  };
  const handleTransactionComplete = () => {
    setOpen(false);
    onCompletion();
  };
  return (
    <>
      <Button variant="contained" color="primary" fullWidth onClick={handleBuy} disabled={requestInProgress}>
        {requestInProgress ? <CircularProgress /> : 'Pay now'}
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box p={2} display="flex" height="100vh" justifyContent="center" alignItems="center">
          <Paper className={classes.modalBody}>
            <Box display="flex" flexDirection="column">
              {verificationInProgress && <LoadingSpinner />}
              <Typography variant="h6">{status}</Typography>
              <Box padding={1} />
              {isTransactionComplete && (
                <Button variant="contained" color="primary" onClick={handleTransactionComplete}>
                  Okey
                </Button>
              )}
            </Box>
          </Paper>
        </Box>
      </Modal>
    </>
  );
};

export default PaymentButton;
