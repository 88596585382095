import { RouteModel } from '@codebee/core';
import { HelpPage } from '@codebee/core/src/components/pages/help';
import DashboardPage from 'components/pages/dashboard/DashboardPage';
import CampBuyerPage from './camp/CampBuyerPage';
import CoursePage from './courses/CoursePage';
import CoursesPage from './courses/CoursesPage';
import PaymentsPage from './payments/PaymentsPage';
export const DASHBOARD_PAGE_ROUTE = '/dashboard';
export const COURSES_PAGE_ROUTE = '/courses';
export const COURSE_PAGE_ROUTE = '/courses/:id';

export const SLOTS_PAGE_ROUTE = '/slots';
export const NEW_SLOT_PAGE_ROUTE = '/slots/new';
export const PAYMENTS_PSAGE_ROUTE = '/payments';
export const CAMP_BUYER = '/buyCamp';
export const CAMP_DETAILS = '/camp';
export const HELP_PAGE_ROUTE = '/help';
export const authenticatedRoutes: RouteModel[] = [
  {
    path: DASHBOARD_PAGE_ROUTE,
    component: DashboardPage,
    exact: true,
    navRoute: {
      icon: 'home',
      exact: true,
      title: 'Dashboard',
    },
  },

  {
    path: COURSES_PAGE_ROUTE,
    component: CoursesPage,
    exact: true,
    navRoute: {
      title: 'Courses',
      icon: 'book',
    },
  },
  {
    path: COURSE_PAGE_ROUTE,
    component: CoursePage,
  },
  {
    path: CAMP_BUYER,
    component: CampBuyerPage,
  },
  {
    path: PAYMENTS_PSAGE_ROUTE,
    component: PaymentsPage,
    exact: true,
    navRoute: { title: 'Payments', icon: 'payment' },
  },
  {
    path: HELP_PAGE_ROUTE,
    component: HelpPage,
    exact: true,
    navRoute: {
      title: 'HelpDesk',
      icon: 'help',
    },
  },
];
