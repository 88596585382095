import { TRIAL_CLASS_PENDING, TRIAL_PLAN } from '@codebee/constants';
import { SLOTS } from '@codebee/constants/collections';
import { SLOT_JOINED } from '@codebee/constants/slotStatus';
import { CenteredMessagePanel, LoadingSpinner, Section } from '@codebee/core/src/components';
import { useUserProfile } from '@codebee/core/src/hooks';
import { Slot } from '@codebee/models/Slot';
import { collection, doc, getDoc, setDoc } from '@firebase/firestore';
import ClassBookingWidget from 'components/organisms/class/ClassBookingWidget';
import ClassRoomWidget from 'components/organisms/class/ClassRoomWidget';
import SlotAction from 'components/organisms/class/SlotAction';
import SlotDetails from 'components/organisms/class/SlotDetails';
import CourseBuyer from 'components/organisms/courses/CourseBuyer';
import Profile from 'components/organisms/profile/Profile';
import React, { Suspense, useEffect, useState } from 'react';
import { useFirestore } from 'reactfire';
export default function CourseDashboardPage() {
  const [userProfile, updateProfile, profileComplete] = useUserProfile();
  const slotsRef = collection(useFirestore(), SLOTS);
  const [showClassRoomBooking, setShowClassRoomBooking] = useState(false);
  const [showClassRoom, setShowClassRoom] = useState(false);

  const hasActivePlan = !!userProfile?.courseId;
  const handleStartClass = async () => {
    setShowClassRoom(true);
    const nextSlotId = userProfile?.nextSlotId || '';
    updateProfile({ nextSessionStatus: SLOT_JOINED });
    const slotDoc = await getDoc(doc(slotsRef, nextSlotId));
    const slot: Slot = slotDoc.data() as Slot;
    const students = slot.students || {};
    students[userProfile?.id || ''] = SLOT_JOINED;
    await setDoc(doc(slotsRef, nextSlotId), { students }, { merge: true });
  };
  useEffect(() => {
    if (profileComplete && userProfile?.plan === TRIAL_PLAN && userProfile.status === TRIAL_CLASS_PENDING) {
      setShowClassRoomBooking(true);
    }
  }, [userProfile, profileComplete]);
  if (!profileComplete) {
    return <Profile />;
  }
  if (!hasActivePlan) {
    return (
      <>
        <CenteredMessagePanel message="You dont have any plans" />
        <CourseBuyer />
      </>
    );
  } else {
    return (
      <Suspense fallback={<LoadingSpinner />}>
        {!showClassRoomBooking && !showClassRoom ? (
          <Section title="Upcoming class">
            {userProfile.nextSlotId ? <SlotDetails slotId={userProfile.nextSlotId} /> : null}
            <SlotAction
              slotId={userProfile.nextSlotId}
              onSchedule={() => setShowClassRoomBooking(true)}
              onStart={handleStartClass}
            />
          </Section>
        ) : null}
        {showClassRoomBooking && <ClassBookingWidget onClose={() => setShowClassRoomBooking(false)} />}
        {showClassRoom && <ClassRoomWidget slotId={userProfile.nextSlotId || ''} />}
      </Suspense>
    );
  }
}
