import { COURSES, DATE_FORMAT, SLOTS, SLOT_BOOKED, TIME_FORMAT } from '@codebee/constants';
import { LabelValue, LoadingSpinner } from '@codebee/core';
import { useUserProfile } from '@codebee/core/src/hooks';
import { collection, doc } from '@firebase/firestore';
import { Button, Grid, Paper } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';
import React, { Suspense, useState } from 'react';
import { useFirestore, useFirestoreDocData, useFirestoreDocDataOnce } from 'reactfire';
import SessionDetails from '../courses/SessionDetails';
type ClassRoomWidgetProps = {
  slotId: string;
};

const useStyles = makeStyles((theme: Theme) => ({}));

const ClassRoomWidget: React.FC<ClassRoomWidgetProps> = (props) => {
  const classes = useStyles();
  const [classStatus, setClassStatus] = useState(SLOT_BOOKED);
  const slotsRef = collection(useFirestore(), SLOTS);
  const [userProfile] = useUserProfile();
  const coursesRef = collection(useFirestore(), COURSES);
  const { data: course } = useFirestoreDocDataOnce(doc(coursesRef, userProfile?.courseId));
  const { data: slot } = useFirestoreDocData(doc(slotsRef, props.slotId), { idField: 'id' });
  const session = course.sessions[userProfile?.nextSessionIndex || ''];
  const date = moment(slot.startTime.toDate()).format(DATE_FORMAT);
  const startTime = moment(slot.startTime.toDate()).format(TIME_FORMAT);

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Grid container>
        <Grid item xs={6}>
          <Paper>
            <LabelValue horizontal label="Topic" value={session.title} />
            <LabelValue label="Start Time" value={startTime} horizontal />
            <LabelValue label="Summary" value={session.summary} horizontal />
            <div>
              <Button variant="contained" color="primary" component="a" href={slot.joinUrl} target="_blank">
                Join Class
              </Button>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          {!!userProfile && <SessionDetails session={session} id={userProfile.nextSessionIndex} />}
        </Grid>
      </Grid>
    </Suspense>
  );
};

export default ClassRoomWidget;
