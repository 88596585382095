import React, { useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';

type SectionProps = {
  title: string;
  alignCenter?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
  title: {
    padding: theme.spacing(1),
  },
  titleCenter: {
    textAlign: 'center',
  },
  contents: {
    margin: theme.spacing(1),
  },
}));

const Section: React.FC<SectionProps> = (props) => {
  const classes = useStyles();
  return (
    <Paper elevation={1} className={classes.root}>
      <Typography
        variant="h5"
        color="initial"
        className={clsx(classes.title, props.alignCenter && classes.titleCenter)}
      >
        {props.title}
      </Typography>
      <div className={classes.contents}>{props.children}</div>
    </Paper>
  );
};

export default Section;
