import { COURSES } from '@codebee/constants/collections';
import { CenteredMessagePanel, LabelValue, LoadingSpinner, TopToolbar } from '@codebee/core';
import { useUserProfile } from '@codebee/core/src/hooks';
import { Course, CourseWithStatus } from '@codebee/models';
import { collection } from '@firebase/firestore';
import { Button, Card, CardActions, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CompletionStatus from 'components/atoms/CompletionStatus';
import React, { Suspense } from 'react';
import { useHistory } from 'react-router-dom';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { COURSES_PAGE_ROUTE } from '../routes';

type CoursesPageProps = {};

const useStyles = makeStyles(() => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
  },
  actions: {},
}));

const CoursesPage: React.FC<CoursesPageProps> = () => {
  const history = useHistory();
  const classes = useStyles();
  const coursesRef = collection(useFirestore(), COURSES);
  const { data: allCourses } = useFirestoreCollectionData(coursesRef, {
    idField: 'id',
  });
  const [userProfile, profile, complete, userDocRef] = useUserProfile();
  const { data: userCourseHistory } = useFirestoreCollectionData(collection(userDocRef, COURSES), {
    idField: 'id',
  });
  const [myCourses, setMyCourses] = React.useState<CourseWithStatus[]>([]);
  React.useEffect(() => {
    if (userCourseHistory && allCourses) {
      const allMyCoures: CourseWithStatus[] = [];
      allCourses.forEach((_course) => {
        const course = _course as Course;
        const profileCourse = (userCourseHistory || []).find((c) => c.id === course.id);
        if (profileCourse) {
          const myCourse: CourseWithStatus = {
            ...course,
            isComplete: profileCourse.isComplete,
            lastCompletedSession: profileCourse.completedSessionIndex,
          };
          allMyCoures.push(myCourse);
        }
      });
      setMyCourses(allMyCoures);
    }
  }, [allCourses, userProfile]);
  return (
    <>
      <TopToolbar leftContent={<Typography variant="h5">Courses</Typography>} />
      <Suspense fallback={<LoadingSpinner size={32} />}>
        <Grid container spacing={1}>
          {myCourses.length === 0 ? (
            <CenteredMessagePanel message="You have not subscribed to any Course" />
          ) : (
            myCourses.map((course) => {
              return (
                <Grid item xs={4} key={course.id}>
                  <Card className={classes.card}>
                    <CardContent className={classes.content}>
                      <Typography color="textSecondary" gutterBottom variant="h4">
                        {course.title}
                        <CompletionStatus
                          isComplete={course.isComplete}
                          text={course.isComplete ? 'Complete' : 'In Progress'}
                        />
                      </Typography>
                      <LabelValue label="Description" value={course.description} />
                    </CardContent>
                    <CardActions className={classes.actions}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => history.push(COURSES_PAGE_ROUTE + '/' + course.id)}
                      >
                        View Course
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })
          )}
        </Grid>
      </Suspense>
    </>
  );
};

export default CoursesPage;
