import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#fd5a52',
    },
    secondary: {
      main: '#fd5a52', //"#ffd0bc",
    },
    background: {
      default: '#fff8f8',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 10,
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: '0px !important',
      },
    },
    MuiAppBar: {
      colorPrimary: {
        color: '#000',
        fontWeight: 900,
        backgroundColor: '#fff8f8',
      },
    },
  },
  props: {
    MuiDrawer: {
      elevation: 0,
    },
    MuiAppBar: {
      elevation: 0,
    },
  },
});

export const ThemeContainer = (props: any) => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export const COLORS = {
  cherryRed: '#f63162',
  lightGrey: '#f6f1f1',
  lightGreyText: '#e9dddd',
  white: '#fff',
};
