import React, { useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

type InvertedLabelProps = {
  value: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  invertedLabel: {
    color: theme.palette.common.white,
  },
}));

const InvertedLabel: React.FC<InvertedLabelProps> = (props) => {
  const classes = useStyles();
  return (
    <Typography variant="subtitle1" className={classes.invertedLabel}>
      {props.value}
    </Typography>
  );
};

export default InvertedLabel;
