import React, { useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

type DashboardPageProps = {};

const useStyles = makeStyles((theme: Theme) => ({}));

export const DashboardPage: React.FC<DashboardPageProps> = (props) => {
  const classes = useStyles();
  return <div>DashboardPage</div>;
};
