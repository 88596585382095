import { LabelValue } from '@codebee/core/src/components/atoms';
import { Session } from '@codebee/models/Course';
import { Card, CardContent, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Launch } from '@material-ui/icons';
import React from 'react';
type SessionDetailsProps = {
  session: Session;
  id: number;
  hideDetails?: boolean;
};

const useStyles = makeStyles(() => ({}));

const SessionDetails: React.FC<SessionDetailsProps> = ({ session, id, hideDetails = false }) => {
  return (
    <Card>
      <CardContent>
        <Typography color="textPrimary" gutterBottom variant="h5">
          {id + 1} - {session.title}
        </Typography>
        <LabelValue label="Summary" value={session.summary} noGap />
        <Typography color="textSecondary" gutterBottom variant="subtitle2">
          Links
        </Typography>
        {hideDetails ? (
          <Typography color="textSecondary" gutterBottom variant="subtitle1">
            You are not allowed view the details
          </Typography>
        ) : (
          <List>
            {session &&
              session.studentMaterials &&
              session.studentMaterials.map((linkItem, index) => (
                <ListItem button component="a" href={linkItem.contents} target="_blank" key={linkItem.title + index}>
                  <ListItemIcon>
                    <Launch />
                  </ListItemIcon>
                  <ListItemText primary={linkItem.title} />
                </ListItem>
              ))}
          </List>
        )}
      </CardContent>
    </Card>
  );
};

export default SessionDetails;
