import { Camp } from '@codebee/models';
import { Timestamp } from '@firebase/firestore';
import { Card, CardActions, CardContent, CardHeader, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';
import React from 'react';
import PaymentButton from './PaymentButton';
type CampCardProps = {
  camp: Camp;
  onBuyComplete: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    color: theme.palette.common.black,
    width: theme.spacing(32),
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    borderRadius: theme.spacing(2),
  },
  title: {
    margin: theme.spacing(3),
  },
  titleRecommanded: {
    color: theme.palette.common.white,
  },
  price: {
    borderBottomWidth: 1,
    // borderBottomStyle: 'solid',
    borderBottomColor: 'inherit',
  },
  curriculam: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(0.5),
  },
  curriculamDetails: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(0.5),
  },
}));

const CampCard: React.FC<CampCardProps> = ({ camp, onBuyComplete }) => {
  const classes = useStyles();
  const start = moment((camp.startTime as unknown as Timestamp).toDate()).format('MMM D, h:mm a');
  const end = moment((camp.endTime as unknown as Timestamp).toDate()).format('MMM D, h:mm a');

  return (
    <Card className={classes.root}>
      <CardHeader title={camp.title} />
      <CardContent>
        <Typography variant="subtitle2" color="initial">
          Starts From
        </Typography>
        <Typography variant="h6" color="textPrimary">
          {start}
        </Typography>
        <Typography variant="caption" color="initial" className={classes.curriculam}>
          Ends On
        </Typography>
        <Typography variant="body1" color="initial" className={classes.curriculamDetails}>
          {end}
        </Typography>

        <Typography variant="h5" color="initial" className={classes.price}>
          ₹ {camp.sellingPrice}
        </Typography>
        <Typography variant="body1" color="initial">
          {camp.sessions.length} classes
        </Typography>
        <Typography variant="caption" color="initial" className={classes.curriculam}>
          Curriculam
        </Typography>
        <Typography variant="body2" color="initial" className={classes.curriculamDetails}>
          {camp.description}
        </Typography>
      </CardContent>
      <CardActions>
        <PaymentButton
          courseId={camp.id}
          amount={camp.sellingPrice}
          onCompletion={() => onBuyComplete()}
          title={camp.title}
        />
      </CardActions>
    </Card>
  );
};

export default CampCard;
