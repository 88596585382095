import { Course } from '@codebee/models';
import { Button, Paper, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

type CourseCardProps = {
  course: Course;
  purchased?: boolean;
  recommended?: boolean;
  onBuyClicked: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.common.black,
    width: theme.spacing(32),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(2),
  },
  recommendedRoot: {
    width: theme.spacing(33),
    border: '2px solid white',
    backgroundImage: 'linear-gradient( #fe7894, #ffb393 )',
    color: theme.palette.common.white,
  },
  title: {
    margin: theme.spacing(3),
  },
  titleRecommanded: {
    color: theme.palette.common.white,
  },
  price: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: 'inherit',
  },
  curriculam: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(0.5),
  },
  curriculamDetails: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(0.5),
  },
  recommended: {},
  recommendedTagContainer: {
    position: 'relative',
    top: theme.spacing(2),
    width: '100%',
    marginLeft: -17,
  },
  recommendedTag: {
    background: '#fff',
    width: '60%',
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
}));

const CourseCard: React.FC<CourseCardProps> = ({ course, onBuyClicked, purchased = false, recommended = false }) => {
  const classes = useStyles();

  return (
    <Paper className={clsx(classes.root, recommended && classes.recommendedRoot)} elevation={recommended ? 8 : 3}>
      {recommended ? (
        <div className={classes.recommendedTagContainer}>
          <Typography variant="caption" color="primary" className={classes.recommendedTag}>
            Recommended
          </Typography>
        </div>
      ) : null}
      <Typography
        variant="subtitle1"
        color="primary"
        className={clsx(classes.title, recommended && classes.titleRecommanded)}
      >
        {course.title}
      </Typography>
      <Typography variant="h5" color="initial" className={classes.price}>
        ₹ {course.price}
      </Typography>
      <Typography variant="body1" color="initial">
        {course.sessions.length} classes
      </Typography>
      <Typography variant="caption" color="initial" className={classes.curriculam}>
        Curriculam
      </Typography>
      <Typography variant="body2" color="initial" className={classes.curriculamDetails}>
        {course.description}
      </Typography>
      {purchased ? (
        <Button variant="outlined" color="primary" disabled>
          Purchased
        </Button>
      ) : (
        <Button variant="contained" color="primary" onClick={() => onBuyClicked()}>
          Buy Course
        </Button>
      )}
    </Paper>
  );
};

export default CourseCard;
