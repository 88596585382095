import React, { useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

type ColoredSectionProps = {};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage: 'linear-gradient(to right, #ffb393 , #fe7894)',
  },
}));

const ColoredSection: React.FC<ColoredSectionProps> = (props) => {
  const classes = useStyles();
  return (
    <Paper elevation={1} className={classes.root}>
      {props.children}
    </Paper>
  );
};

export default ColoredSection;
