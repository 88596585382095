import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { LoadingSpinner, TopToolbar } from '..';

export type PageShellProps = {
  title: string;
  loading?: boolean;
  error?: string;
  children: React.ReactNode;
  showBackButton?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({}));

const PageShell: React.FC<PageShellProps> = ({
  title,
  loading = false,
  children,
  error = '',
  showBackButton = false,
}) => {
  const classes = useStyles();
  return (
    <div>
      <TopToolbar leftContent={<Typography variant="h5">{title}</Typography>} showBackButton={showBackButton} />

      {loading ? <LoadingSpinner /> : <Box>{error ? <Typography color="error">{error}</Typography> : children}</Box>}
    </div>
  );
};

export default PageShell;
