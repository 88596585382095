import React, { useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import TopToolbar, { TopToolbarProps } from '../toolbar/TopToolbar';

export type PageContainerProps = {
  topToolbar?: TopToolbarProps;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  contents: {
    // marginTop: theme.spacing(1),
    flexGrow: 1,
    padding: theme.spacing(1),
  },
}));

const PageContainer: React.FC<PageContainerProps> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {props.topToolbar && <TopToolbar {...props.topToolbar} />}
      <Paper className={classes.contents}>{props.children}</Paper>
    </div>
  );
};

export default PageContainer;
