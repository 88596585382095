import React from 'react';
import { useSigninCheck } from 'reactfire';
import { LoadingSpinner } from '../components/molecules/loadingSpinner';
import AppContext from './AppContext';

export type AuthCheckProps = {
  fallback: React.ReactNode;
};
const AuthCheck: React.FC<AuthCheckProps> = (props) => {
  const { status, data: signInCheckResult } = useSigninCheck();
  if (status === 'loading') {
    return <LoadingSpinner />;
  }

  return (
    <>
      {signInCheckResult.signedIn ? (
        <AppContext.Provider value={{ loggedInUserId: signInCheckResult.user.uid }}>
          {props.children}
        </AppContext.Provider>
      ) : (
        props.fallback
      )}
    </>
  );
};

export default AuthCheck;
