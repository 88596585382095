import { Container } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { AccountCircle } from '@material-ui/icons';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import { Route } from 'react-router-dom';
import { useAuth } from 'reactfire';
import { RouteModel } from '../..';
import logo from '../../app/octorunLogo.png';
import { useUserProfile } from '../../hooks';
// import { useUser } from "../../utils/auth/useUser";
import { NavLink } from '../atoms';
import './corelayoutStyles.css';
const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerRoot: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
    },
    expand: {
      flexGrow: 1,
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    activeLink: {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      paddingTop: theme.spacing(3),
    },
    userName: {
      margin: theme.spacing(1),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#757373',
    },
  }),
);

export type CoreLayout = {
  routes: RouteModel[];
};
const CoreLayout: React.FC<CoreLayout> = ({ routes }) => {
  const classes = useStyles();
  const theme = useTheme();
  const auth = useAuth();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [userProfile] = useUserProfile();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logout = () => {
    auth.signOut();
  };
  const drawer = (
    <div className={classes.drawerRoot}>
      <div className={classes.toolbar}>
        <img src={logo} alt="Octorun" id="logo" />
      </div>
      <div className={classes.userName}>
        <AccountCircle fontSize="large" color="inherit" />
        <Typography variant="h5" color="inherit">
          {userProfile?.name}
        </Typography>
      </div>
      <List>
        {routes.map((route) =>
          route.navRoute ? (
            <NavLink
              key={route.path}
              path={route.path}
              exact={route.navRoute.exact}
              icon={route.navRoute.icon}
              text={route.navRoute.title}
            />
          ) : null,
        )}
      </List>
      <div className={classes.expand} />
      <List>
        <ListItem button onClick={() => logout()}>
          <ListItemIcon>
            <ExitToAppIcon />
            <ListItemText primary={'Logout'} />
          </ListItemIcon>
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Hidden smUp implementation="css">
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Hidden>

      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <Hidden smUp implementation="css">
          <div className={classes.toolbar} />
        </Hidden>
        <Container maxWidth="lg">
          {routes.map((route) => (
            <Route key={route.path} path={route.path} exact={route.exact} component={route.component} />
          ))}
        </Container>
      </main>
    </div>
  );
};

export default CoreLayout;
