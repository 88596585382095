import {
  AGE_GROUP_NINE_TO_ELEVEN,
  AGE_GROUP_SIX_TO_EIGHT,
  AGE_GROUP_TWELEVE_TO_FOURTEEN,
  COLLECTION_CONFIG,
  COLLECTION_COURSE_CONFIG,
  COURSES,
  TRIAL_CLASS_PENDING,
  TRIAL_PLAN,
  USERS,
} from '@codebee/constants';
import { LoadingSpinner, Section } from '@codebee/core';
import AppContext from '@codebee/core/src/app/AppContext';
import { CourseConfigModel, UserProfile } from '@codebee/models';
import { collection, doc, setDoc, Timestamp } from '@firebase/firestore';
import { Button, MenuItem, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useContext } from 'react';
import { useFirestore, useFirestoreCollectionData, useFirestoreDocData, useFirestoreDocDataOnce } from 'reactfire';

type ProfileProps = {};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    width: theme.spacing(50),
  },
  buttonContainer: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
}));

const Profile: React.FC<ProfileProps> = (props) => {
  const classes = useStyles();
  const context = useContext(AppContext);
  const profileRef = doc(useFirestore(), USERS, context.loggedInUserId);
  const { status, data: userProfile } = useFirestoreDocData(profileRef);
  // const [userProfile, updateProfile, profileComplete] = useUserProfile();
  // const { control, errors, handleSubmit, register } = useForm<UserProfile>({ defaultValues: userProfile });
  const courseConfigRef = doc(useFirestore(), COLLECTION_CONFIG, COLLECTION_COURSE_CONFIG);
  const { data: courseConfigData } = useFirestoreDocDataOnce<CourseConfigModel>(courseConfigRef);

  const coursesRef = collection(useFirestore(), COURSES);
  const { data: allCourses } = useFirestoreCollectionData(coursesRef, { idField: 'id' });

  const onSubmit = (data: UserProfile) => {
    if (!!data.kidsAgeGroup) {
      const configKey = data.kidsAgeGroup + '_' + TRIAL_PLAN;
      const trialCourseId = courseConfigData[configKey];
      data.planType = TRIAL_PLAN;
      data.courseId = trialCourseId;
      data.plan = TRIAL_PLAN;
      data.createdOn = Timestamp.now();
      const trialCourse = allCourses.find((course) => course.id === trialCourseId);
      if (trialCourse) {
        data.courseTitle = trialCourse.title;
        data.nextSessionIndex = 0;
        data.nextSessionTitle = trialCourse.sessions[0].title;
        data.nextSessionStatus = TRIAL_CLASS_PENDING;
      }
    }
    setDoc(profileRef, data, { merge: true });

    // updateProfile(data);
  };
  return (
    <Section title="Update Profile">
      <Typography variant="subtitle2" color="initial">
        We just need few details before booking{' '}
        <Typography variant="h5" color="primary" style={{ display: 'inline-flex' }}>
          FREE
        </Typography>{' '}
        trial Class
      </Typography>
      {status === 'loading' ? (
        <LoadingSpinner />
      ) : (
        <Formik
          initialValues={userProfile}
          onSubmit={(values, { setSubmitting }) => {
            // setSubmitting(true);
            onSubmit(values as UserProfile);
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <div className={classes.root}>
                <Field component={TextField} name="name" label="Parents Name" helperText="" fullWidth />
                <Field component={TextField} name="kidsName" label="Kid's Name" helperText="" fullWidth />
                <Field
                  component={TextField}
                  name="kidsAgeGroup"
                  label="Age Group"
                  helperText=""
                  fullWidth
                  select={true}
                >
                  <MenuItem aria-label="None" value="" />
                  <MenuItem value={AGE_GROUP_SIX_TO_EIGHT}>{AGE_GROUP_SIX_TO_EIGHT}</MenuItem>
                  <MenuItem value={AGE_GROUP_NINE_TO_ELEVEN}>{AGE_GROUP_NINE_TO_ELEVEN}</MenuItem>
                  <MenuItem value={AGE_GROUP_TWELEVE_TO_FOURTEEN}>{AGE_GROUP_TWELEVE_TO_FOURTEEN}</MenuItem>
                </Field>
                <div className={classes.buttonContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={submitForm}
                    disabled={isSubmitting}
                  >
                    Book Free Trial Class
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
      {/* <form onSubmit={handleSubmit(onSubmit)}>
        
          <TextField
            className={classes.formControl}
            name="name"
            label="Parents Name"
            type="outlined"
            error={!!errors.name}
            helperText={errors.name?.message}
            inputRef={register({
              required: 'Required',
            })}
          />
          <TextField
            className={classes.formControl}
            name="kidsName"
            label="Kid's Name"
            type="outlined"
            error={!!errors.kidsName}
            helperText={errors.kidsName?.message}
            inputRef={register({
              required: 'Required',
            })}
          />
          <FormControl variant="standard" className={classes.formControl}>
            <InputLabel htmlFor="kidsAgeGroup">Age Group</InputLabel>
            <Select
              id="kidsAgeGroup"
              native
              error={!!errors.kidsAgeGroup}
              inputRef={register({
                required: 'Select an Age Group',
              })}
              inputProps={{
                name: 'kidsAgeGroup',
              }}
            >
              <option aria-label="None" value="" />
              <option value={AGE_GROUP_SIX_TO_EIGHT}>{AGE_GROUP_SIX_TO_EIGHT}</option>
              <option value={AGE_GROUP_NINE_TO_ELEVEN}>{AGE_GROUP_NINE_TO_ELEVEN}</option>
              <option value={AGE_GROUP_TWELEVE_TO_FOURTEEN}>{AGE_GROUP_TWELEVE_TO_FOURTEEN}</option>
            </Select>
            <FormHelperText>{errors.kidsAgeGroup?.message}</FormHelperText>
          </FormControl>
         
        </div>
      </form> */}
    </Section>
  );
};

export default Profile;
