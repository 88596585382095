import { CampSession } from '@codebee/models';
import { Timestamp } from '@firebase/firestore';
import { Box, Button, Modal, Paper, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CompletionStatus from 'components/atoms/CompletionStatus';
import SessionDetails from 'components/organisms/courses/SessionDetails';
import moment from 'moment';
import React, { useState } from 'react';
export type CampSessionCardProps = {
  session: CampSession;
  index: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
  },
}));

const CampSessionCard: React.FC<CampSessionCardProps> = ({ session, index }) => {
  const classes = useStyles();
  const startTime = (session.startTime as unknown as Timestamp).toDate();
  const endTime = (session.endTime as unknown as Timestamp).toDate();
  const [deatailsOpen, setDeatailsOpen] = useState(false);

  return (
    <Paper className={classes.root}>
      <Box p={2} display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{session.title}</Typography>
          {session.completed && <CompletionStatus isComplete={session.completed} text="Completed" />}
          {/* <Typography variant="caption" color="secondary">
            {session.completed ? 'Completed' : ''}
          </Typography> */}
        </Box>
        <Typography variant="caption">
          {moment(startTime).format('MMM D, h:mm a')} - {moment(endTime).format('MMM D, h:mm a')}
        </Typography>
        <Typography variant="body1">{session.summary}</Typography>
        <Box p={1} display="flex" justifyContent="center">
          {session.completed ? (
            <Button variant="outlined" color="primary" onClick={() => setDeatailsOpen(true)}>
              View Details
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              component="a"
              href={session.joinUrl}
              target="_blank"
              disabled={!session.joinUrl}
            >
              Join
            </Button>
          )}
        </Box>
      </Box>
      <Modal open={deatailsOpen} onClose={() => setDeatailsOpen(false)}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          onClick={() => setDeatailsOpen(false)}
        >
          <SessionDetails id={index} session={session} />
          <Button
            variant="text"
            color="default"
            onClick={(event) => {
              setDeatailsOpen(false);
            }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </Paper>
  );
};

export default CampSessionCard;
