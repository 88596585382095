import React from "react";
import { CircularProgress } from "@material-ui/core";

import { makeStyles, Theme } from "@material-ui/core/styles";

type LoadingSpinnerProps = {
  size?: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingTop: "7rem",
    height: "100%",
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50%",
  },
}));

const LoadingSpinner:React.FC<LoadingSpinnerProps>= ({ size = 80 }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.progress}>
        <CircularProgress size={size} />
      </div>
    </div>
  );
};

export default LoadingSpinner;
