import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Paper, Typography } from '@material-ui/core';

type CenteredMessagePanelProps = {
  message?: string;
  actionText?: string;
  onActionClicked?: () => void;
  size?: 'small' | 'large';
  withMargin?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: theme.spacing(20),
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    flexGrow: 1,
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  container: {
    marginTop: theme.spacing(1),
  },
}));

const CenteredMessagePanel: React.FC<CenteredMessagePanelProps> = (props) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <div className={classes.content}>
        {props.message && (
          <Typography variant="body1" color="initial" className={classes.container}>
            {props.message}
          </Typography>
        )}
        {props.actionText && (
          <div className={classes.container}>
            <Button variant="contained" color="primary" onClick={props.onActionClicked}>
              {props.actionText}
            </Button>
          </div>
        )}
      </div>
    </Paper>
  );
};

export default CenteredMessagePanel;
