import * as firebaseui from 'firebaseui';
import React from 'react';

const ELEMENT_ID = 'firebaseui_container';
let firebaseUiDeletion = Promise.resolve();
export interface FirebaseAuthProps {
  uiConfig: firebaseui.auth.Config;
  uiCallback?(ui: firebaseui.auth.AuthUI): void;
  firebaseAuth: any; // As firebaseui-web
  className?: string;
}
export interface FirebaseAuthState {
  uiConfig: firebaseui.auth.Config;
  uiCallback?(ui: firebaseui.auth.AuthUI): void;
  firebaseAuth: any; // As firebaseui-web
  className?: string;
  unregisterAuthObserver: () => void;
}

export default class FirebaseAuth extends React.Component<FirebaseAuthProps, FirebaseAuthState> {
  uiConfig: firebaseui.auth.Config;
  className: string | undefined;
  firebaseAuth: any;
  uiCallback: ((ui: firebaseui.auth.AuthUI) => void) | undefined;
  unregisterAuthObserver: () => void;
  firebaseUiWidget: any;
  userSignedIn = false;
  constructor(props: FirebaseAuthProps) {
    super(props);

    this.uiConfig = props.uiConfig;
    this.firebaseAuth = props.firebaseAuth;
    this.className = props.className;
    this.uiCallback = props.uiCallback;
    this.unregisterAuthObserver = () => {};
  }

  /**
   * @inheritDoc
   */
  componentDidMount() {
    // Import the css only on the client.
    require('firebaseui/dist/firebaseui.css');

    // Firebase UI only works on the Client. So we're loading the package in `componentDidMount`
    // So that this works when doing server-side rendering.
    const firebaseui = require('firebaseui');

    // Wait in case the firebase UI instance is being deleted.
    // This can happen if you unmount/remount the element quickly.
    return firebaseUiDeletion.then(() => {
      // Get or Create a firebaseUI instance.
      this.firebaseUiWidget =
        firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(this.props.firebaseAuth);
      if (this.uiConfig.signInFlow === 'popup') {
        this.firebaseUiWidget.reset();
      }

      // We track the auth state to reset firebaseUi if the user signs out.
      this.userSignedIn = false;
      this.unregisterAuthObserver = this.firebaseAuth.onAuthStateChanged((user: any) => {
        if (!user && this.userSignedIn) {
          this.firebaseUiWidget.reset();
        }
        this.userSignedIn = !!user;
      });

      // Trigger the callback if any was set.
      if (this.uiCallback) {
        this.uiCallback(this.firebaseUiWidget);
      }

      // Render the firebaseUi Widget.
      this.firebaseUiWidget.start('#' + ELEMENT_ID, this.uiConfig);
    });
  }

  /**
   * @inheritDoc
   */
  componentWillUnmount() {
    firebaseUiDeletion = firebaseUiDeletion.then(() => {
      this.unregisterAuthObserver();
      return this.firebaseUiWidget.delete();
    });
    return firebaseUiDeletion;
  }

  render() {
    return <div className={this.props.className} id={ELEMENT_ID} />;
  }
}
