import { ConfirmationResult, getAuth, RecaptchaVerifier, signInWithPhoneNumber, UserCredential } from '@firebase/auth';
import { Box, Button, CircularProgress, InputAdornment, TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
declare global {
  interface Window {
    recaptchaVerifier: any;
  }
}
export type FirebasePhoneAuthProps = {
  signInSuccessUrl: string;
  signedInCallBack?: (userCreds: UserCredential) => void;
};

const useStyles = makeStyles((theme: Theme) => ({}));

const FirebasePhoneAuth: React.FC<FirebasePhoneAuthProps> = ({ signInSuccessUrl, signedInCallBack }) => {
  const classes = useStyles();
  const auth = getAuth();
  const history = useHistory();
  auth.useDeviceLanguage();
  const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult | undefined>();
  //   let confirmationResult: ConfirmationResult | null = null;
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [verificationInProgress, setVerificationInProgress] = useState(false);
  const [otpVerificationInProgress, setOtpVerificationInProgress] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [otpVerficationError, setOtpVerficationError] = useState('');
  useEffect(() => {
    if (!auth) {
      return;
    }
    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        callback: (response: any) => {
          console.log(' response ', response);
        },
        'expired-callback': () => {
          console.log(' response Expired');
        },
      },
      auth,
    );
  }, [auth]);
  const handleReset = () => {
    setVerificationInProgress(false);
    setPhoneNumberError('');
    setPhoneNumber('');
    setOtp('');
    setOtpVerficationError('');
    setOtpVerificationInProgress(false);
    setOtpVerficationError('');
    setConfirmationResult(undefined);
  };
  const handlePhoneNumberSubmit = () => {
    const regex = /^[0-9]{10}$/;
    const valid = regex.test(phoneNumber);
    if (!valid) {
      setPhoneNumberError('Enter valid phone number');
    } else {
      setPhoneNumberError('');
      setVerificationInProgress(true);
      signInWithPhoneNumber(auth, `+91${phoneNumber}`, window.recaptchaVerifier)
        .then((_confirmationResult) => {
          setConfirmationResult(_confirmationResult);
        })
        .catch((error) => {
          console.error(error);
          setVerificationInProgress(false);
          setPhoneNumberError(!error.message ? 'Failed to send OTP, try again later' : error.message);
        });
    }
  };
  const handleOtpSubmit = () => {
    setOtpVerficationError('');
    setOtpVerificationInProgress(true);
    confirmationResult
      ?.confirm(otp)
      .then((result) => {
        setOtpVerificationInProgress(false);
        if (signedInCallBack) {
          signedInCallBack(result);
        } else {
          history.replace(signInSuccessUrl);
        }
      })
      .catch((error) => {
        setOtpVerificationInProgress(false);
        setOtpVerficationError(!error.message ? 'Failed to validate OTP, try again later' : error.message);
      });
  };
  const handlePhoneNumberChange = (value: string) => {
    setPhoneNumberError('');
    setPhoneNumber(value);
  };
  return (
    <Box display="flex" flexDirection="column">
      <TextField
        label="Phone Number"
        autoFocus
        id="standard-start-adornment"
        value={phoneNumber}
        type="number"
        disabled={verificationInProgress}
        required
        error={!!phoneNumberError}
        helperText={phoneNumberError}
        onChange={(event) => handlePhoneNumberChange(event.target.value)}
        inputProps={{ maxLength: 10 }}
        InputProps={{
          startAdornment: <InputAdornment position="start">+91</InputAdornment>,
        }}
      />
      <Box height={16} />

      {!!confirmationResult && (
        <TextField
          label="Enter OTP"
          autoFocus
          id="standard-start-adornment"
          value={otp}
          type="number"
          required
          error={!!otpVerficationError}
          helperText={otpVerficationError}
          onChange={(event) => setOtp(event.target.value)}
        />
      )}
      <Box height={32} />
      {!confirmationResult && (
        <Button variant="contained" color="primary" id="getOtp" onClick={handlePhoneNumberSubmit}>
          Get OTP
        </Button>
      )}
      {!!confirmationResult && (
        <>
          <Button
            variant="contained"
            color="primary"
            id="signin"
            onClick={handleOtpSubmit}
            disabled={otp.trim().length === 0 || otpVerificationInProgress}
          >
            Sign In
          </Button>
          {otpVerificationInProgress && <CircularProgress size={16} />}
        </>
      )}
      <Box height={32} />
      <div id="recaptcha-container" />

      <Box height={32} />
      <Button variant="outlined" color="secondary" onClick={handleReset}>
        Reset
      </Button>
    </Box>
  );
};

export default FirebasePhoneAuth;
