import { Camp } from '@codebee/models';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CampSessionCard from 'components/molecules/CampSessionCard';
import { Timestamp } from 'firebase/firestore';
import moment from 'moment';
import React from 'react';
export type CampDetailsProps = {
  camp: Camp;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
}));

const CampDetails: React.FC<CampDetailsProps> = ({ camp }) => {
  const classes = useStyles();
  // const firestore = useFirestore();
  // const campRef = doc(firestore, CAMPS, props.campId);
  // const { status, data } = useFirestoreDocData(campRef, { idField: 'id' });
  // if (status === 'loading') {
  //   return <LoadingSpinner />;
  // }
  // const camp = data as Camp;
  const startTime = (camp.startTime as unknown as Timestamp).toDate();
  const endTime = (camp.endTime as unknown as Timestamp).toDate();
  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">{camp.title}</Typography>
        <Typography variant="body1" color="secondary">
          {camp.active ? 'Active' : 'Not Active'}
        </Typography>
      </Box>
      <Typography variant="caption">
        {moment(startTime).format('MMMM Do YYYY')} - {moment(endTime).format('MMMM Do YYYY')}
      </Typography>
      <Paper elevation={0} variant="outlined">
        <Box p={2}>
          <Typography variant="body1">{camp.description}</Typography>
        </Box>
      </Paper>
      <Box mt={2}>
        <Grid container spacing={2}>
          {Object.keys(camp.sessions).map((index) => (
            <Grid item key={index} xs={3}>
              <CampSessionCard key={index} session={camp.sessions[index]} index={+index} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

export default CampDetails;
