import React, { useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

type CourseMaterialIFrameViewerProps = {
  link: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

const CourseMaterialIFrameViewer: React.FC<CourseMaterialIFrameViewerProps> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <iframe title="doc" style={{ width: '100%', border: 'none' }} src={props.link + '?embedded=true'}></iframe>
    </div>
  );
};

export default CourseMaterialIFrameViewer;
