import {
  ECONOMY_PLAN,
  MAXIMUM_STUDENTS_FOR_ECONOMY_CLASS,
  PREMIUM_PLAN,
  SLOTS,
  SLOT_BOOKED,
  SLOT_BOOKING_FULL,
  TRIAL_CLASS_BOOKED,
  TRIAL_PLAN,
} from '@codebee/constants';
import { PAID_SLOT, TRIAL_SLOT } from '@codebee/constants/slotTypes';
import { COLORS } from '@codebee/core';
import { ColoredSection, InvertedLabel, LabelValue, Section } from '@codebee/core/src/components/atoms';
import { useUserProfile } from '@codebee/core/src/hooks';
import { Slot } from '@codebee/models/Slot';
import { collection, doc, orderBy, query, setDoc } from '@firebase/firestore';
import { Button } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import moment from 'moment';
import React, { useState } from 'react';
import DatePicker from 'react-horizontal-datepicker';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import SlotSelector from '../../molecules/SlotSelector';

type ClassBookingWidgetProps = {
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  datePicker: {
    background: '#FFF',
    padding: theme.spacing(1),
    // marginRight: theme.spacing(10),
    // marginLeft: theme.spacing(10),
    maxWidth: theme.spacing(70),
    borderRadius: theme.spacing(1),
  },
  withPadding: {
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  courseDetailsContainer: {
    maxWidth: theme.spacing(70),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(2),
  },
  courseDetails: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    marginTop: 0,
    background: COLORS.white,
    width: '100%',
    display: 'flex',
    justifyContent: 'start',
    borderRadius: theme.spacing(0.5),
  },
}));

const ClassBookingWidget: React.FC<ClassBookingWidgetProps> = (props) => {
  const classes = useStyles();

  const [userProfile, updateProfile] = useUserProfile();
  const slotsRef = collection(useFirestore(), SLOTS);
  const [availableSlots, setAvailableSlots] = useState<Slot[]>([]);
  const { data: allSlots } = useFirestoreCollectionData(query(slotsRef, orderBy('startTime')), { idField: 'id' });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedSlot, setSelectedSlot] = useState<Slot | null>(null);

  React.useEffect(() => {
    const now = moment();
    const startTime = now.add(20, 'minutes');
    let nextSlots = (allSlots || []).filter(
      (slot) => moment(slot.startTime.toDate()).isAfter(startTime) && slot.status !== SLOT_BOOKING_FULL,
    );
    if (userProfile?.planType === TRIAL_PLAN) {
      nextSlots = nextSlots.filter((slot) => slot.type === TRIAL_SLOT);
    } else if (userProfile?.plan === PREMIUM_PLAN) {
      nextSlots = nextSlots.filter((slot) => slot.teacherId === userProfile?.teacherId && slot.type === PAID_SLOT);
    } else if (userProfile?.plan === ECONOMY_PLAN) {
      nextSlots = nextSlots.filter((slot) => {
        if (slot.type !== PAID_SLOT) {
          return false;
        }
        if (slot.courseId && slot.sessionIndex) {
          return userProfile?.courseId === slot.courseId && userProfile?.nextSessionIndex === slot.sessionIndex;
        }
        return true;
      });
    }
    setAvailableSlots(nextSlots as Slot[]);
  }, [allSlots, userProfile]);

  const handleDateChange = (value: Date) => {
    setSelectedDate(value);
    setSelectedSlot(null);
  };
  const handleBookClass = () => {
    if (selectedSlot) {
      if (!userProfile) {
        return;
      }
      const updatedSlot = { ...selectedSlot };
      const updatedStudents = updatedSlot.students || {};
      updatedStudents[userProfile?.id] = SLOT_BOOKED;
      const studentIds = Object.keys(updatedStudents);

      updatedSlot.courseId = userProfile?.courseId;
      updatedSlot.sessionIndex = userProfile?.nextSessionIndex;
      updatedSlot.status =
        userProfile?.planType !== ECONOMY_PLAN
          ? SLOT_BOOKING_FULL
          : studentIds.length === MAXIMUM_STUDENTS_FOR_ECONOMY_CLASS
          ? SLOT_BOOKING_FULL
          : SLOT_BOOKED;
      updatedSlot.students = updatedStudents;
      updatedSlot.title = userProfile?.courseTitle + '-' + userProfile?.nextSessionTitle;
      setDoc(doc(slotsRef, updatedSlot.id), updatedSlot, { merge: true });
      updateProfile({
        nextSlotId: selectedSlot.id,
        nextSlotTime: selectedSlot.startTime,
        status: TRIAL_CLASS_BOOKED,
        nextSessionStatus: SLOT_BOOKED,
      });
      props.onClose();
    }
  };

  return (
    <Section title="Choose Slot" alignCenter>
      <div className={classes.withPadding}>
        <ColoredSection>
          <InvertedLabel value="Book your class" />
          <div className={classes.datePicker}>
            <DatePicker
              getSelectedDay={(value: any) => handleDateChange(value)}
              endDate={100}
              selectDate={selectedDate}
              labelFormat={'MMM yyyy'}
              color={'#fd5a52'}
            />
          </div>
          <div className={classes.courseDetailsContainer}>
            <InvertedLabel value="Class Details" />
            <div className={classes.courseDetails}>
              <LabelValue label="Course" value={userProfile?.courseTitle || ''}></LabelValue>
              <LabelValue label="Session" value={userProfile?.nextSessionTitle || ''}></LabelValue>
            </div>
          </div>
          <SlotSelector
            allSlots={availableSlots}
            selectedSlot={selectedSlot}
            onSlotSelected={(slot) => setSelectedSlot(slot)}
            selectedDate={selectedDate}
          />
        </ColoredSection>
      </div>

      <div className={clsx(classes.withPadding)}>
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={!selectedSlot}
            fullWidth
            style={{ maxWidth: 300 }}
            onClick={handleBookClass}
          >
            Book
          </Button>
        </div>
      </div>
    </Section>
  );
};

export default ClassBookingWidget;
