import { DATE_FORMAT, SLOTS, TIME_FORMAT } from '@codebee/constants';
import { LabelValue, LoadingSpinner } from '@codebee/core';
import { useUserProfile } from '@codebee/core/src/hooks';
import { collection, doc } from '@firebase/firestore';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';
import React from 'react';
import { useFirestore, useFirestoreDocData } from 'reactfire';
type SlotDetailsProps = {
  slotId: string;
};

const useStyles = makeStyles((theme: Theme) => ({}));

const SlotDetails: React.FC<SlotDetailsProps> = (props) => {
  const classes = useStyles();
  const [userProfile] = useUserProfile();
  const slotsRef = collection(useFirestore(), SLOTS);
  const { data: slot, status } = useFirestoreDocData(doc(slotsRef, props.slotId), { idField: 'id' });
  if (status === 'loading') {
    return <LoadingSpinner />;
  }
  return renderSlot();

  function renderSlot() {
    if (!slot) return null;
    const date = moment(slot.startTime.toDate()).format(DATE_FORMAT);
    const startTime = moment(slot.startTime.toDate()).format(TIME_FORMAT);
    const endTime = moment(slot.endTime.toDate()).format(TIME_FORMAT);
    return (
      <Grid container>
        <Grid item>
          <LabelValue label="Date" value={date} />
        </Grid>
        <Grid item>
          <LabelValue label="Start Time" value={startTime} />
        </Grid>
        <Grid item>
          <LabelValue label="End Time" value={endTime} />
        </Grid>
        <Grid item>
          <LabelValue label="Status" value={slot.status} />
        </Grid>
        <Grid item>
          <LabelValue label="Course" value={userProfile?.courseTitle || ''} />
        </Grid>
        <Grid item>
          <LabelValue label="Session" value={userProfile?.nextSessionTitle || ''} />
        </Grid>
      </Grid>
    );
  }
};

export default SlotDetails;
