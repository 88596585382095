import React from "react";
import { Typography, makeStyles, Theme } from "@material-ui/core";
export type HeaderIconProps = {
  icon: React.ReactNode;
  title: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  iconContainer: {
    flexGrow: 1,
    width: 52,
    height: 48,
    borderRadius: 12,
    background:
      "linear-gradient(90deg, rgba(253,94,86,1) 0%, rgba(255,137,136,1) 100%)",
    color: "#FFF",
    boxShadow: "#c5b2c8 0px 4px 8px 1px",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  icon: {
    alignItems: "center",
    display: "flex",
    flexGrow: 1,
  },
  title: {
    marginLeft: theme.spacing(1),
  },
}));

const Header: React.FC<HeaderIconProps> = ({ icon, title }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.iconContainer}>
        <div className={classes.icon}>{icon}</div>
      </div>
      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>
    </div>
  );
};
export default Header;
