import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

type HelpPageProps = {};

const useStyles = makeStyles((theme: Theme) => ({}));

export const HelpPage: React.FC<HelpPageProps> = (props) => {
  const classes = useStyles();
  return (
    <div>
      <iframe
        style={{ width: '100%', border: 'none', height: '80vh' }}
        title="contactus"
        src="https://docs.google.com/document/d/e/2PACX-1vRtetgLSdTPXQHkCrQaGAt-wD4IJzZZ7nx5kahwAO_k2DWeCi1id6NtfYnRhHa8xxKit_dMvHwVWhPn/pub?embedded=true"
      ></iframe>
    </div>
  );
};
