import { TRANSACTIONS } from '@codebee/constants';
import { CenteredMessagePanel, LoadingSpinner, TopToolbar } from '@codebee/core';
import AppContext from '@codebee/core/src/app/AppContext';
import { collection, orderBy, query, Timestamp, where } from '@firebase/firestore';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { DataGrid, GridColumns, GridValueGetterParams } from '@mui/x-data-grid';
import React, { useContext } from 'react';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

export type PaymentsPageProps = {};

const useStyles = makeStyles((theme: Theme) => ({}));

const columns: GridColumns = [
  {
    field: 'startTime',
    width: 200,
    headerName: 'Date',
    type: 'dateTime',
    valueGetter: (params: GridValueGetterParams) => {
      return (params.value as Timestamp).toDate();
    },
    sortable: true,
  },
  {
    field: 'razorpayOrder',
    width: 150,
    align: 'right',
    headerName: 'Amount (₹)',
    type: 'number',
    valueGetter: (params: GridValueGetterParams) => {
      // debugger;
      return (params.value as any).amount / 100;
    },
  },
  {
    field: 'status',
    align: 'left',
    width: 150,
    headerName: 'Status',
    type: 'string',
    valueGetter: (params: GridValueGetterParams) => {
      return params.value === 'In Progress' ? 'Incomplete' : params.value;
    },
  },
  {
    field: 'type',
    align: 'left',
    width: 150,
    headerName: 'Type',
    type: 'string',
  },
  { field: 'title', headerName: 'Title', width: 250 },
  { field: 'courseId', headerName: 'Course/Camp Id', width: 250 },

  { field: 'id', headerName: 'ID', width: 250 },
];

const PaymentsPage: React.FC<PaymentsPageProps> = (props) => {
  const classes = useStyles();
  const firestore = useFirestore();
  const transactionsRef = collection(firestore, TRANSACTIONS);
  const appContext = useContext(AppContext);
  const myTransactions = query(
    transactionsRef,
    where('userId', '==', appContext.loggedInUserId),
    orderBy('startTime', 'desc'),
  );
  const { data, status } = useFirestoreCollectionData(myTransactions, { idField: 'id' });

  return (
    <div>
      <TopToolbar leftContent={<Typography variant="h5">Payments</Typography>} showBackButton />

      {status === 'loading' ? (
        <LoadingSpinner />
      ) : !data || data.length == 0 ? (
        <CenteredMessagePanel message="No Transactions" />
      ) : (
        <Paper style={{ height: '85vh' }}>
          <DataGrid rows={data} columns={columns} sortingMode={'client'} />
        </Paper>
      )}
    </div>
  );
};

export default PaymentsPage;
